import { schema } from 'normalizr'
import { assoc } from 'ramda'
import documentsSchema from './documentsSchema'
import organizationalUnitSchema from './organizationalUnitSchema'

const idAttribute = 'id'
const relations = {
  documents: [documentsSchema],
  organizationalUnit: organizationalUnitSchema,
}

export default new schema.Entity('packages', relations, {
  idAttribute,
  processStrategy: (entity) => assoc('type', 'package', entity),
})
