import { prop, propOr } from 'ramda'
import { AnyAction } from 'redux'
import update from 'utility/update'

export interface State {
  loading: boolean
  error?: string
  credentials: {
    key?: string
    departmentSKS?: string
    hospitalSKS?: string
  }
}

const initialState: State = {
  loading: true,
  error: undefined,
  credentials: {
    key: undefined,
    departmentSKS: undefined,
    hospitalSKS: undefined,
  },
}

export default function appReducer(state: State = initialState, { payload, type }: AnyAction): State {
  switch (type) {
    case 'app/INITIALIZE': {
      return update(state, {
        loading: {
          $set: true,
        },
      })
    }
    case 'app/INITIALIZED': {
      return update(state, {
        loading: {
          $set: false,
        },
      })
    }
    case 'app/SET_CREDENTIALS': {
      return update(state, {
        credentials: {
          key: {
            $set: propOr(undefined, 'key', payload),
          },
          departmentSKS: {
            $set: propOr(undefined, 'departmentSKS', payload),
          },
          hospitalSKS: {
            $set: propOr(undefined, 'hospitalSKS', payload),
          },
        },
      })
    }
    case 'app/SET_ERROR': {
      return update(state, {
        error: {
          $set: prop('error', payload),
        },
      })
    }
  }

  return state
}
