import { normalize } from 'normalizr'
import { SagaIterator } from 'redux-saga'
import { put, takeLatest } from 'redux-saga/effects'
import ticketsSchema from 'schemas/ticketsSchema'
import api from 'api'
import createAction from 'utility/createAction'
import { AnyAction } from 'redux'
import { pluck } from 'ramda'
import { Document, Ticket } from 'entities'

function* fetchRequest({ payload }: AnyAction) {
  try {
    const { id } = payload

    const response = (yield api.get(`/ticket/${id}`)) as { ticket: Ticket }
    const normalized = normalize(response.ticket, ticketsSchema)

    yield put(createAction('data/FETCH_TICKET_SUCCESS', normalized))
  } catch (error) {
    yield put(createAction('data/FETCH_TICKET_FAILURE', { error }))
  }
}

function* createRequest({ payload }: AnyAction) {
  try {
    const { closeUrl } = payload

    const response = (yield api.post(`/ticket?closeUrl=${closeUrl}`)) as { ticket: Ticket }
    const normalized = normalize(response.ticket, ticketsSchema)

    yield put(createAction('data/CREATE_TICKET_SUCCESS', normalized))
  } catch (error) {
    yield put(createAction('data/CREATE_TICKET_FAILURE', { error }))
  }
}

function* addDocumentsRequest({ payload }: AnyAction) {
  try {
    const { ticket, documents }: { ticket: Ticket; documents: Document[] } = payload

    const response = (yield api.patch(`/ticket/${ticket.id}/documents`, {
      documents: pluck('id', documents),
    })) as { ticket: Ticket }

    const normalized = normalize(response.ticket, ticketsSchema)

    yield put(createAction('data/ADD_DOCUMENTS_SUCCESS', normalized))
  } catch (error) {
    yield put(createAction('data/ADD_DOCUMENTS_FAILURE', { error }))
  }
}

function* replaceDocumentsRequest({ payload }: AnyAction) {
  try {
    const { ticket, documents }: { ticket: Ticket; documents: Document[] } = payload

    const response = (yield api.put(`/ticket/${ticket.id}/documents`, {
      documents: pluck('id', documents),
    })) as { ticket: Ticket }

    const normalized = normalize(response.ticket, ticketsSchema)

    yield put(createAction('data/REPLACE_DOCUMENTS_SUCCESS', normalized))
  } catch (error) {
    yield put(createAction('data/REPLACE_DOCUMENTS_FAILURE', { error }))
  }
}

function* removeDocumentsRequest({ payload }: AnyAction) {
  try {
    const { ticket, documents }: { ticket: Ticket; documents: Document[] } = payload

    const response = (yield api.post(`/ticket/${ticket.id}/documents/delete`, {
      documents: pluck('id', documents),
    })) as { ticket: Ticket }

    const normalized = normalize(response.ticket, ticketsSchema)

    yield put(createAction('data/REMOVE_DOCUMENTS_SUCCESS', normalized))
  } catch (error) {
    yield put(createAction('data/REMOVE_DOCUMENTS_FAILURE', { error }))
  }
}

export default function* ticketsSaga(): SagaIterator {
  yield takeLatest('data/FETCH_TICKET_REQUEST', fetchRequest)
  yield takeLatest('data/CREATE_TICKET_REQUEST', createRequest)
  yield takeLatest('data/ADD_DOCUMENTS_REQUEST', addDocumentsRequest)
  yield takeLatest('data/REMOVE_DOCUMENTS_REQUEST', removeDocumentsRequest)
  yield takeLatest('data/REPLACE_DOCUMENTS_REQUEST', replaceDocumentsRequest)
}
