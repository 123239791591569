import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { FiShoppingCart } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import ticketSelector from 'store/selectors/ticketSelector'

interface Props {
  onClick: () => void
  className: string
}
export default function TicketBasket({ onClick, className }: Props) {
  const [ping, setPing] = useState(false)
  const { ticketItems } = useSelector(ticketSelector)

  useEffect(() => {
    setPing(true)
    const timeout = setTimeout(() => {
      setPing(false)
    }, 1000)

    return () => clearTimeout(timeout)
  }, [ticketItems])

  return (
    <button
      onClick={onClick}
      className={cx('flex', 'items-center', 'relative', 'hover:shadow-outline-blue-900', className)}
    >
      <FiShoppingCart size={24} className="mr-2" /> Valgte bilag
      <span className="absolute top-0 right-0 -mr-2 -mt-1 w-5 h-5 flex">
        {ping && (
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-800 opacity-75"></span>
        )}
        <span className="relative inline-flex rounded-full w-5 h-5 text-xs bg-blue-900 text-white justify-center items-center">
          {ticketItems}
        </span>
      </span>
    </button>
  )
}
