import React, { forwardRef, useCallback, useImperativeHandle } from 'react'
import cx from 'classnames'
import { FiChevronLeft } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import organizationalUnitsSelector from 'store/selectors/organizationalUnitsSelector'
import filtersSelector from 'store/selectors/filtersSelector'
import OrganizationalUnitsTree from 'components/OrganizationalUnitsTree'
import useComponentOpen from 'utility/useComponentOpen'
import createAction from 'utility/createAction'
import { OrganizationalUnit } from 'entities'

export interface OrganizationalUnitsSidebarComponent {
  toggle: () => void
}

export default forwardRef<OrganizationalUnitsSidebarComponent>((_, forwardedRef) => {
  const dispatch = useDispatch()
  const { organizationalUnits } = useSelector(organizationalUnitsSelector)
  const { organizationalUnit } = useSelector(filtersSelector)

  const { ref, isOpen, setIsOpen } = useComponentOpen(false)

  useImperativeHandle(
    forwardedRef,
    () => ({
      toggle() {
        setIsOpen(!isOpen)
      },
    }),
    [setIsOpen, isOpen]
  )

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const handleSelect = useCallback(
    (organizationalUnit: OrganizationalUnit) => {
      dispatch(
        createAction('attachments/filters/SET', {
          key: 'organizationalUnit',
          value: organizationalUnit.id,
        })
      )
      setIsOpen(false)
    },
    [dispatch, setIsOpen]
  )

  const className = cx(
    [
      'absolute',
      'z-40',
      'top-0',
      'bottom-0',
      'w-80',
      'overflow-hidden',
      'bg-gray-200',
      'transition-all',
      'duration-300',
      'shadow-md',
      'resize-x',
    ],
    {
      '-left-80': !isOpen,
      'left-0': isOpen,
    }
  )

  return (
    <div>
      <div className={className} ref={ref}>
        <div className="px-4 pt-2">
          <div className="mb-3 flex justify-between items-center">
            <h3 className="text-2xl tracking-wide text-gray-800">Organisatoriske enheder</h3>

            <button onClick={handleClose} className="focus:outline-none text-gray-600 hover:text-gray-800">
              <FiChevronLeft size={28} />
            </button>
          </div>
          <OrganizationalUnitsTree
            organizationalUnits={organizationalUnits}
            handleSelect={handleSelect}
            selectedId={organizationalUnit}
          />
        </div>
      </div>
    </div>
  )
})
