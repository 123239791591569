import React, { useCallback, ChangeEvent } from 'react'

type Props = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  title: string
  value: boolean
  filterKey: string
  onChange: (filterKey: string, value: boolean) => void
  onClear: (filterKey: string) => void
}

export default function BooleanFilter({ title, filterKey, value, onChange, onClear, ...otherProps }: Props) {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(filterKey, e.target.checked)
    },
    [filterKey, onChange]
  )

  return (
    <div {...otherProps}>
      <div className="flex items-center">
        <input
          type="checkbox"
          id={filterKey}
          className="w-4 h-4 cursor-pointer"
          checked={value}
          onChange={handleChange}
        />
      </div>
      <label htmlFor={filterKey} className="text-blue-900 text-xs cursor-pointer select-none">
        {title}
      </label>
    </div>
  )
}
