import { pluck } from 'ramda'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { FiChevronRight } from 'react-icons/fi'
import TicketContent from 'components/TicketContent'
import { PreviewComponent } from 'components/Preview'
import ticketSelector from 'store/selectors/ticketSelector'
import useComponentOpen from 'utility/useComponentOpen'
import createAction from 'utility/createAction'
import size from 'utility/size'
import update from 'utility/update'
import { ReactComponent as Loading } from 'assets/svg/loading.svg'

export interface TicketSidebarComponent {
  toggle: () => void
}

interface Props {
  previewRef: React.RefObject<PreviewComponent>
}

export default forwardRef<TicketSidebarComponent, Props>(({ previewRef }, forwardedRef) => {
  const dispatch = useDispatch()
  const [isModified, setIsModified] = useState(false)
  const { ref, isOpen, setIsOpen } = useComponentOpen(false)
  const { ticket, ticketSize, valid, isValid, isLoading } = useSelector(ticketSelector)
  const [documents, setDocuments] = useState(ticket.documents)

  useImperativeHandle(
    forwardedRef,
    () => ({
      toggle() {
        setIsOpen(!isOpen)
      },
    }),
    [setIsOpen, isOpen]
  )

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const handleSend = useCallback(() => {
    dispatch(createAction('ticket/SEND'))
  }, [dispatch])

  const handleSave = useCallback(() => {
    dispatch(createAction('ticket/SET_DOCUMENTS', { documents }))
  }, [dispatch, documents])

  const handlePreview = useCallback(
    (document) => {
      previewRef.current?.open(document)
    },
    [previewRef]
  )

  const handleRemove = useCallback(
    (document) => {
      dispatch(createAction('ticket/REMOVE_DOCUMENT', { document }))
    },
    [dispatch]
  )

  const handleMove = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const draggedDocument = documents[dragIndex]
      setDocuments(
        update(documents, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, draggedDocument],
          ],
        })
      )
    },
    [documents]
  )

  useEffect(() => {
    setDocuments(ticket.documents)
  }, [ticket])

  useEffect(() => {
    setIsModified(JSON.stringify(pluck('id', documents)) !== JSON.stringify(pluck('id', ticket.documents)))
  }, [documents, ticket])

  const className = cx(
    [
      'absolute',
      'z-40',
      'top-0',
      'bottom-0',
      'w-96',
      'overflow-hidden',
      'bg-gray-200',
      'transition-all',
      'duration-300',
      'shadow-md',
      'resize-x',
      'max-h-screen',
    ],
    {
      '-right-96': !isOpen,
      'right-0': isOpen,
    }
  )

  return (
    <div className={className} ref={ref}>
      <div className="pt-2 pb-4 flex flex-col h-screen">
        {isLoading && (
          <div className="absolute inset-0 flex justify-center items-center text-blue-500 bg-white bg-opacity-50">
            <Loading />
          </div>
        )}

        <div className="px-4 mb-3 flex justify-between items-center">
          <button onClick={handleClose} className="focus:outline-none text-gray-600 hover:text-gray-900">
            <FiChevronRight size={28} />
          </button>

          <h3 className="text-2xl tracking-wide text-gray-800">Valgte bilag</h3>
        </div>

        <div className="flex-1 overflow-y-auto flex flex-col items-start">
          <DndProvider backend={HTML5Backend}>
            <TicketContent
              onMove={handleMove}
              onPreview={handlePreview}
              onRemove={handleRemove}
              documents={documents}
              valid={valid}
            />
          </DndProvider>
        </div>

        <div className="flex flex-col px-4">
          {!valid.combineWithLetter && (
            <div className="bg-red-100 border rounded-md border-red-500 text-red-700 p-3 text-sm mb-2">
              <p>
                Du har valgt mere end ét forberedelsesbilag og kan ikke overføre til DiPo, før der kun er
                valgt ét.
              </p>
            </div>
          )}
          {!valid.size && (
            <div className="bg-yellow-100 border rounded-md border-yellow-500 text-yellow-700 p-3 text-sm mb-2">
              <p>
                Du har valgt bilag som tilsammen fylder mere end 7MB, som kan medføre at du ikke kan sende som
                Digital Post.
              </p>
            </div>
          )}
          {!valid.documents && (
            <div className="bg-yellow-100 border rounded-md border-yellow-500 text-yellow-700 p-3 text-sm mb-2">
              <p>Du har valgt mere end 10 bilag, som kan medføre at du ikke kan sende som Digital Post</p>
            </div>
          )}
          <div className="flex flex-row justify-between items-center mb-2">
            <span className="text-yellow-600 text-sm">
              {isModified && <React.Fragment>Indkøbsvogn er ændret</React.Fragment>}
            </span>
            <span className="text-gray-600">Samlet størrelse: {size(ticketSize)}</span>
          </div>
          {isModified ? (
            <button
              onClick={handleSave}
              className={cx(
                'focus:outline-none',
                'text-yellow-100',
                'rounded-md',
                'p-5',
                'text-center',
                'bg-yellow-500',
                'hover:shadow-outline-yellow-500'
              )}
            >
              Gem ændringer
            </button>
          ) : (
            <button
              disabled={!isValid}
              onClick={handleSend}
              className={cx('focus:outline-none', 'text-blue-100', 'rounded-md', 'p-5', 'text-center', {
                'bg-gray-600 cursor-not-allowed': !isValid,
                'bg-blue-500 hover:shadow-outline': isValid,
              })}
            >
              Overfør bilag til DiPo
            </button>
          )}
        </div>
      </div>
    </div>
  )
})
