import { normalize } from 'normalizr'
import { SagaIterator } from 'redux-saga'
import { put, takeLatest } from 'redux-saga/effects'
import organizationalUnitSchema from 'schemas/organizationalUnitSchema'
import api from 'api'
import createAction from 'utility/createAction'
import { OrganizationalUnit } from 'entities'

function* fetchRequest() {
  try {
    const response = (yield api.get('/organizationalUnits')) as OrganizationalUnit[]

    const organizationalUnits = normalize(response, [organizationalUnitSchema])

    yield put(createAction('data/FETCH_ORGANIZATIONAL_UNITS_SUCCESS', organizationalUnits))
  } catch (error) {
    yield put(createAction('data/FETCH_ORGANIZATIONAL_UNITS_FAILURE', { error }))
  }
}

export default function* organizationalUnitsSaga(): SagaIterator {
  yield takeLatest('data/FETCH_ORGANIZATIONAL_UNITS_REQUEST', fetchRequest)
}
