import {
  all,
  allPass,
  always,
  any,
  ascend,
  assoc,
  chain,
  compose,
  concat,
  cond,
  curry,
  descend,
  either,
  equals,
  F,
  filter,
  flatten,
  flip,
  identity,
  ifElse,
  includes,
  is,
  isNil,
  map,
  path,
  pathEq,
  pathOr,
  pipe,
  pluck,
  prop,
  propEq,
  propOr,
  propSatisfies,
  sortWith,
  split,
  sum,
  toLower,
} from 'ramda'
import { denormalize } from 'normalizr'
import { createSelector, createStructuredSelector } from 'reselect'
import documentsSchema from 'schemas/documentsSchema'
import { Attachment, DenormalizedAttachment, Document, OrganizationalUnit, Package, Ticket } from 'entities'
import packagesSchema from 'schemas/packagesSchema'
import filtersSelector from './filtersSelector'
import { selectedTicket } from './ticketSelector'
import { isFalsy } from 'ramda-adjunct'

interface Selection {
  isLoading: boolean
  sortingKey: string
  sortingDirection: 'asc' | 'desc'
  attachments: DenormalizedAttachment[]
}

const selectOrganizationalUnits = pathOr({}, ['data', 'organizationalUnits', 'entities'])
const selectIsLoading = pathOr(false, ['data', 'attachments', 'loading'])
const selectIds = pathOr({}, ['data', 'attachments', 'result'])
const selectSortingKey = pathOr<string>('title', ['attachments', 'sorting', 'key'])
const selectSortingDirection = pathOr<'asc' | 'desc'>('asc', ['attachments', 'sorting', 'direction'])

interface AttachmentsSelection {
  organizationalUnits: Record<number, OrganizationalUnit>
  documents: Record<number, Document>
  packages: Record<number, Package>
}

const entitiesSelector = createStructuredSelector({
  organizationalUnits: selectOrganizationalUnits,
  documents: pathOr({}, ['data', 'attachments', 'entities', 'documents']),
  packages: pathOr({}, ['data', 'attachments', 'entities', 'packages']),
})

const attachmentsSelector = (ids: Object, entities: AttachmentsSelection) => {
  const { documents, packages } = denormalize(
    ids,
    { documents: [documentsSchema], packages: [packagesSchema] },
    entities
  )
  return concat<Attachment[]>(documents)(packages)
}

export const selectedAttachments = createSelector(selectIds, entitiesSelector, attachmentsSelector)

const includesIgnoreCase = curry((s1: string, s2: string) =>
  includes(String(s1).toLowerCase(), String(s2).toLowerCase())
)

const flippedIncludes = flip(includes)

const filteredAttachmentsSelector = (
  attachments: Attachment[],
  filters: Record<string, any>,
  organizationalUnits: Record<number, OrganizationalUnit>
) => {
  const titleFilter = prop('title', filters)
  const organizationalUnitFilter = prop('organizationalUnit', filters)
  const healthConditionCategoryFilter = prop('healthConditionCategory', filters)
  const hideSublevels = prop('hideSublevels', filters)
  const combineWithLetter = prop('combineWithLetter', filters)
  const typeFilter = prop('type', filters)
  const documentCategoryFilter = prop('documentCategory', filters)
  const organizationalUnitFilterFilter = prop('organizationalUnitFilter', filters)

  const selectedOrganizationalUnit = prop(organizationalUnitFilter, organizationalUnits)

  const attachmentFilters = [
    ...(!isNil(typeFilter) ? [propEq('type', typeFilter)] : []),
    either(
      allPass([
        propEq('type', 'document'),
        either(
          either(
            pathEq(['organizationalUnit', 'id'], organizationalUnitFilter),
            pathEq(['sharedOrganizationalUnit'], organizationalUnitFilter)
          ),
          isFalsy(hideSublevels)
            ? compose(
                flippedIncludes(pathOr([], ['childrenIds'], selectedOrganizationalUnit)),
                path<any>(['organizationalUnit', 'id'])
              )
            : F
        ),
      ]),
      allPass([
        propEq('type', 'package'),
        either(
          pathEq(['organizationalUnit', 'id'], organizationalUnitFilter),
          allPass([
            always(propEq('level', 3)(selectedOrganizationalUnit)),
            isFalsy(hideSublevels)
              ? compose(
                  flippedIncludes(pathOr([], ['childrenIds'], selectedOrganizationalUnit)),
                  path<any>(['organizationalUnit', 'id'])
                )
              : F,
          ])
        ),
      ])
    ),
    either(
      allPass([propEq('type', 'document'), propSatisfies(includesIgnoreCase(titleFilter), 'title')]),
      allPass([
        propEq('type', 'package'),
        either(
          propSatisfies(includesIgnoreCase(titleFilter), 'title'),
          compose(any(propSatisfies(includesIgnoreCase(titleFilter), 'title')), propOr([], 'documents'))
        ),
      ])
    ),
    ...(!isNil(healthConditionCategoryFilter)
      ? [
          either(
            allPass([
              propEq('type', 'document'),
              compose(
                any(propEq('oid', prop('id', healthConditionCategoryFilter))),
                propOr([], 'healthConditions')
              ),
            ]),
            allPass([
              propEq('type', 'package'),
              compose(
                any(propEq('oid', prop('id', healthConditionCategoryFilter))),
                flatten,
                pluck('healthConditions'),
                propOr([], 'documents')
              ),
            ])
          ),
        ]
      : []),
    ...(!isNil(documentCategoryFilter)
      ? [
          either(
            allPass([
              propEq('type', 'document'),
              pathEq(['documentCategory', 'oid'], prop('id', documentCategoryFilter)),
            ]),
            allPass([
              propEq('type', 'package'),
              compose(
                any(pathEq(['documentCategory', 'oid'], prop('id', documentCategoryFilter))),
                propOr([], 'documents')
              ),
            ])
          ),
        ]
      : []),
    ...(!isNil(organizationalUnitFilterFilter)
      ? [
          either(
            allPass([
              propEq('type', 'document'),
              pathEq(['organizationalUnitFilter', 'oid'], prop('id', organizationalUnitFilterFilter)),
            ]),
            either(
              allPass([
                propEq('type', 'package'),
                compose(
                  any(
                    pathEq(['organizationalUnitFilter', 'oid'], prop('id', organizationalUnitFilterFilter))
                  ),
                  propOr([], 'documents')
                ),
              ]),
              allPass([
                propEq('type', 'package'),
                pathEq(['organizationalUnitFilter', 'oid'], prop('id', organizationalUnitFilterFilter)),
              ])
            )
          ),
        ]
      : []),
    ...(!isNil(combineWithLetter)
      ? [
          either(
            allPass([propEq('type', 'document'), propEq('combineWithLetter', combineWithLetter)]),
            allPass([
              propEq('type', 'package'),
              compose(any(propEq('combineWithLetter', combineWithLetter)), propOr([], 'documents')),
            ])
          ),
        ]
      : []),
  ]

  return filter(allPass(attachmentFilters))(attachments)
}

const denormalizedAttachmentsSelector = (
  attachments: Attachment[],
  ticket: Ticket
): DenormalizedAttachment[] => {
  const documentsInTicket: number[] = pluck('id', ticket.documents)

  const documentIsAdded = (attachment: Document) => includes(prop('id', attachment), documentsInTicket)
  const packageIsAdded = (attachment: Package) => all(documentIsAdded, attachment.documents)

  const isAdded = cond<any, boolean>([
    [propEq('type', 'document'), documentIsAdded],
    [propEq('type', 'package'), packageIsAdded],
  ])

  const documentSize = pathOr<number>(0, ['file', 'size'])
  const packageSize = compose(sum, map(pathOr(0, ['file', 'size'])), pathOr([], ['documents']))

  const size = cond<any, number>([
    [propEq('type', 'document'), documentSize],
    [propEq('type', 'package'), packageSize],
  ])

  return map(pipe(chain(assoc('isAdded'), isAdded), chain(assoc('size'), size)), attachments)
}

const sortedAttachmentsSelector = (
  attachments: DenormalizedAttachment[],
  key: string,
  direction: 'asc' | 'desc'
) => {
  const sortDirection = ifElse(equals('desc'), always(descend), always(ascend))
  const sortKey = compose(ifElse(is(String), toLower, identity), path<any>(split('.', key)))

  const attachmentPackagesFirst = descend(prop('type'))

  return sortWith([attachmentPackagesFirst, sortDirection(direction)(sortKey)], attachments)
}

export const selectedFilteredAttachments = createSelector(
  selectedAttachments,
  filtersSelector,
  selectOrganizationalUnits,
  filteredAttachmentsSelector
)

const selectedDenormalizedAttachments = createSelector(
  selectedFilteredAttachments,
  selectedTicket,
  denormalizedAttachmentsSelector
)

export default createStructuredSelector<Record<string, unknown>, Selection>({
  isLoading: createSelector(selectIsLoading, identity),
  sortingKey: createSelector(selectSortingKey, identity),
  sortingDirection: createSelector(selectSortingDirection, identity),
  attachments: createSelector(
    selectedDenormalizedAttachments,
    selectSortingKey,
    selectSortingDirection,
    sortedAttachmentsSelector
  ),
})
