import { pathOr } from 'ramda'
import { AnyAction } from 'redux'
import { Document, Package } from 'entities'
import update from 'utility/update'

export interface State {
  loading: boolean
  result: {
    documents: number[]
    packages: number[]
  }
  entities: {
    documents: Record<string, Document>
    packages: Record<string, Package>
  }
}

const initialState: State = {
  loading: false,
  result: {
    documents: [],
    packages: [],
  },
  entities: {
    documents: {},
    packages: {},
  },
}

export default function attachmentsReducer(state: State = initialState, { payload, type }: AnyAction): State {
  switch (type) {
    case 'data/FETCH_ATTACHMENTS_REQUEST': {
      return update(state, {
        loading: {
          $set: true,
        },
      })
    }
    case 'data/FETCH_ATTACHMENTS_SUCCESS': {
      return update(state, {
        entities: {
          documents: {
            $set: pathOr({}, ['entities', 'documents'], payload),
          },
          packages: {
            $set: pathOr({}, ['entities', 'packages'], payload),
          },
        },
        result: {
          documents: {
            $set: pathOr([], ['result', 'documents'], payload),
          },
          packages: {
            $set: pathOr([], ['result', 'packages'], payload),
          },
        },
        loading: {
          $set: false,
        },
      })
    }
    case 'data/FETCH_TICKET_SUCCESS': {
      return update(state, {
        entities: {
          documents: {
            $merge: pathOr({}, ['entities', 'documents'], payload),
          },
        },
        loading: {
          $set: false,
        },
      })
    }
    case 'data/FETCH_ATTACHMENTS_FAILURE': {
      return update(state, {
        loading: {
          $set: false,
        },
      })
    }
    case 'data/RESET': {
      return initialState
    }
  }

  return state
}
