import cx from 'classnames'
import React, { useCallback } from 'react'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import attachmentsSelector from 'store/selectors/attachmentsSelector'
import { PreviewComponent } from 'components/Preview'
import AttachmentTableRow from 'components/AttachmentTableRow'
import createAction from 'utility/createAction'

interface Props {
  previewRef: React.RefObject<PreviewComponent>
}

export const AttachmentTable = ({ previewRef }: Props) => {
  const dispatch = useDispatch()
  const { attachments, sortingKey, sortingDirection } = useSelector(attachmentsSelector)

  const handlePreview = useCallback(
    (attachment) => {
      previewRef.current?.open(attachment)
    },
    [previewRef]
  )

  const handleAdd = useCallback(
    (attachment) => {
      dispatch(createAction('ticket/ADD_ATTACHMENT', { attachment }))
    },
    [dispatch]
  )

  const handleRemove = useCallback(
    (attachment) => {
      dispatch(createAction('ticket/REMOVE_ATTACHMENT', { attachment }))
    },
    [dispatch]
  )

  const handleSort = useCallback(
    (key) => {
      dispatch(
        createAction('attachments/sorting/SET', {
          key,
          direction:
            key === sortingKey
              ? sortingDirection === 'asc'
                ? 'desc'
                : 'asc'
              : key === 'updatedAt'
              ? 'desc'
              : 'asc',
        })
      )
    },
    [dispatch, sortingKey, sortingDirection]
  )

  return (
    <table className="table-fixed w-full">
      <thead>
        <tr>
          <td className="px-4 py-3 w-5/12">
            <button
              className="flex flex-row items-center focus:outline-none text-sm"
              onClick={() => handleSort('title')}
            >
              <div className="flex flex-col items-center pt-1 mr-1 text-gray-500">
                <FaCaretUp
                  size={14}
                  className={cx('-my-1', {
                    'text-gray-900': sortingKey === 'title' && sortingDirection === 'asc',
                  })}
                />
                <FaCaretDown
                  size={14}
                  className={cx({
                    'text-gray-900': sortingKey === 'title' && sortingDirection === 'desc',
                  })}
                />
              </div>
              Titel
            </button>
          </td>
          <td className="px-3 py-3"></td>
          <td className="px-3 py-3 w-2/12">
            <button
              className="flex flex-row items-center focus:outline-none text-sm"
              onClick={() => handleSort('organizationalUnit.name')}
            >
              <div className="flex flex-col items-center pt-1 mr-1 text-gray-500">
                <FaCaretUp
                  size={14}
                  className={cx('-my-1', {
                    'text-gray-900': sortingKey === 'organizationalUnit.name' && sortingDirection === 'asc',
                  })}
                />
                <FaCaretDown
                  size={14}
                  className={cx({
                    'text-gray-900': sortingKey === 'organizationalUnit.name' && sortingDirection === 'desc',
                  })}
                />
              </div>
              Organisatorisk enhed
            </button>
          </td>
          <td className="px-3 py-3 w-2/12">
            <button
              className="flex flex-row items-center focus:outline-none text-sm"
              onClick={() => handleSort('updatedAt')}
            >
              <div className="flex flex-col items-center pt-1 mr-1 text-gray-500">
                <FaCaretUp
                  size={14}
                  className={cx('-my-1', {
                    'text-gray-900': sortingKey === 'updatedAt' && sortingDirection === 'asc',
                  })}
                />
                <FaCaretDown
                  size={14}
                  className={cx({
                    'text-gray-900': sortingKey === 'updatedAt' && sortingDirection === 'desc',
                  })}
                />
              </div>
              Sidst opdateret
            </button>
          </td>
        </tr>
      </thead>
      <tbody>
        {attachments.map((attachment, index) => (
          <AttachmentTableRow
            key={attachment.id}
            index={index}
            attachment={attachment}
            onPreview={handlePreview}
            onAdd={handleAdd}
            onRemove={handleRemove}
          />
        ))}

        {attachments.length === 0 && (
          <tr>
            <td colSpan={4} className="text-center text-sm text-gray-600 py-6">
              Ingen vedhæftede filer matcher filterkriterierne...
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default AttachmentTable
