import { AnyAction } from 'redux'

interface Payload {
  [key: string]: unknown
}

interface Meta {
  [key: string]: unknown
}

export default function createAction(type: string, payload: Payload = {}, meta: Meta = {}): AnyAction {
  return { type, payload, meta }
}
