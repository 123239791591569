import { forEach } from 'ramda'

interface FlatItem {
  id: number
  parentId: number
}

export default function hierarchize<T extends FlatItem>(items: T[]) {
  const hashTable = Object.create(null)

  forEach((item) => {
    hashTable[item.id] = { ...item, children: [] }
  }, items)

  const dataTree: T[] = []

  forEach((item) => {
    if (item.parentId > 1) {
      hashTable[item.parentId].children.push(hashTable[item.id])
    } else {
      dataTree.push(hashTable[item.id])
    }
  }, items)

  return dataTree
}
