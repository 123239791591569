import {
  Attachment,
  Document,
  HealthConditionCategory,
  DocumentCategory,
  OrganizationalUnitFilter,
} from 'entities'
import { filter, flatten, isNil, map, pluck, propEq, reject, uniq } from 'ramda'
import { renameKeys } from 'ramda-adjunct'
import { createSelector, createStructuredSelector } from 'reselect'
import { selectedAttachments } from 'store/selectors/attachmentsSelector'

interface Option {
  id: number
  name: string
}

interface Selection {
  healthConditionCategoryOptions: Option[]
  documentCategoryOptions: Option[]
  organizationalUnitFilterOptions: Option[]
}

const filteredDocumentsSelector = (attachments: Attachment[]) => {
  return filter(propEq('type', 'document'), attachments) as Document[]
}

const healthConditionCategoryOptionsSelector = (documents: Document[]) => {
  return uniq([
    ...map<HealthConditionCategory, Option>(
      renameKeys({ oid: 'id' }) as any,
      flatten(pluck('healthConditions', documents))
    ),
  ])
}

const documentsCategoryOptionsSelector = (documents: Document[]) => {
  return uniq([
    ...map<DocumentCategory | null, Option>(
      renameKeys({ oid: 'id' }) as any,
      reject(isNil)(flatten(pluck('documentCategory', documents)))
    ),
  ])
}

const organizationalUnitFilterSelector = (attachments: Attachment[]) => {
  return uniq([
    ...map<OrganizationalUnitFilter | null, Option>(
      renameKeys({ oid: 'id' }) as any,
      reject(isNil)(flatten(pluck('organizationalUnitFilter', attachments)))
    ),
  ])
}

const selectedFilteredDocuments = createSelector(selectedAttachments, filteredDocumentsSelector)

export default createStructuredSelector<Record<string, unknown>, Selection>({
  healthConditionCategoryOptions: createSelector(
    selectedFilteredDocuments,
    healthConditionCategoryOptionsSelector
  ),
  documentCategoryOptions: createSelector(selectedFilteredDocuments, documentsCategoryOptionsSelector),
  organizationalUnitFilterOptions: createSelector(selectedAttachments, organizationalUnitFilterSelector),
})
