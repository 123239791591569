import { combineReducers } from 'redux'

import data from './data/dataRootReducer'
import app from './reducers/appReducer'
import attachments from './reducers/attachmentsReducer'
import notifications from './reducers/notificationsReducer'

export default combineReducers({
  data,

  app,
  attachments,
  notifications,
})
