import { pathOr } from 'ramda'
import { AnyAction } from 'redux'
import { Ticket } from 'entities'
import update from 'utility/update'

export interface State {
  loading: boolean
  entities: Record<number, Ticket>
  result?: number
}

const initialState: State = {
  loading: false,
  entities: {},
  result: undefined,
}

export default function ticketsReducer(state: State = initialState, { payload, type }: AnyAction): State {
  switch (type) {
    case 'data/FETCH_TICKET_REQUEST':
    case 'data/CREATE_TICKET_REQUEST':
    case 'data/ADD_DOCUMENTS_REQUEST':
    case 'data/REMOVE_DOCUMENTS_REQUEST':
    case 'data/REPLACE_DOCUMENTS_REQUEST': {
      return update(state, {
        loading: {
          $set: true,
        },
      })
    }
    case 'data/FETCH_TICKET_SUCCESS':
    case 'data/CREATE_TICKET_SUCCESS':
    case 'data/ADD_DOCUMENTS_SUCCESS':
    case 'data/REMOVE_DOCUMENTS_SUCCESS':
    case 'data/REPLACE_DOCUMENTS_SUCCESS': {
      return update(state, {
        entities: {
          $set: pathOr({}, ['entities', 'tickets'], payload),
        },
        result: {
          $set: pathOr(undefined, ['result'], payload),
        },
        loading: {
          $set: false,
        },
      })
    }
    case 'data/FETCH_TICKET_FAILURE':
    case 'data/CREATE_TICKET_FAILURE':
    case 'data/ADD_DOCUMENTS_FAILURE':
    case 'data/REMOVE_DOCUMENTS_FAILURE':
    case 'data/REPLACE_DOCUMENTS_FAILURE': {
      return update(state, {
        loading: {
          $set: false,
        },
      })
    }
    case 'data/RESET': {
      return initialState
    }
  }

  return state
}
