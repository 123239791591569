import React, { useCallback, useState } from 'react'
import cx from 'classnames'
import { length } from 'ramda'
import { FcDepartment } from 'react-icons/fc'
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import { OrganizationalUnit } from 'entities'

interface Props {
  selectedId: number | undefined
  organizationalUnits: OrganizationalUnit[]
  handleSelect: (organizationalUnit: OrganizationalUnit) => void
}

const OrganizationalUnitsTree = ({ organizationalUnits, selectedId, handleSelect }: Props) => {
  const [isOpen, setIsOpen] = useState(true)

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  return (
    <div>
      {organizationalUnits.map((organizationalUnit) => (
        <React.Fragment key={organizationalUnit.id}>
          {length(organizationalUnit.children!) > 0 ? (
            <div className="flex items-center">
              <button onClick={handleToggle} className="text-gray-500 focus:outline-none hover:text-blue-500">
                {isOpen ? <FiMinusCircle size={16} /> : <FiPlusCircle size={16} />}
              </button>
              <div className="w-6">
                <FcDepartment className="mx-1" size={18} />
              </div>
              <button
                title={organizationalUnit.name}
                onClick={() => handleSelect(organizationalUnit)}
                className={cx([
                  'truncate',
                  'focus:outline-none',
                  'focus:text-blue-500',
                  {
                    'text-gray-800': selectedId !== organizationalUnit.id,
                    'text-blue-500': selectedId === organizationalUnit.id,
                  },
                ])}
              >
                {organizationalUnit.name}
              </button>
            </div>
          ) : (
            <div className="pl-4 flex items-center">
              <div className="w-6">
                <FcDepartment className="mr-1" size={18} />
              </div>
              <button
                title={organizationalUnit.name}
                onClick={() => handleSelect(organizationalUnit)}
                className={cx([
                  'truncate',
                  'focus:outline-none',
                  'focus:text-blue-500',
                  {
                    'text-gray-800': selectedId !== organizationalUnit.id,
                    'text-blue-500': selectedId === organizationalUnit.id,
                  },
                ])}
              >
                {organizationalUnit.name}
              </button>
            </div>
          )}

          {organizationalUnit.children && isOpen && (
            <div className="pl-3 py-1">
              <OrganizationalUnitsTree
                organizationalUnits={organizationalUnit.children}
                handleSelect={handleSelect}
                selectedId={selectedId}
              />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default OrganizationalUnitsTree
