import { useState, useEffect, useRef } from 'react'

export default function useComponentOpen(initial: boolean) {
  const [isOpen, setIsOpen] = useState(initial)
  const ref = useRef(null)

  const handleClickOutside = (event: MouseEvent) => {
    // @ts-ignore
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return { ref, isOpen, setIsOpen }
}
