import { Context, CustomCommands, Spec } from 'immutability-helper'
import { without } from 'ramda'

interface Commands {
  $without?: any[]
}

export default function update<T>(object: T, spec: Spec<T, CustomCommands<Commands>>) {
  const myContext = new Context()

  myContext.extend('$without', without)

  return myContext.update(object, spec)
}
