import React, { useCallback, useEffect, useState } from 'react'
import { Notification as NotificationEntity } from 'entities'
import cx from 'classnames'
import { FiX } from 'react-icons/fi'

interface Props {
  notification: NotificationEntity
  animationIn: string
  animationOut: string
  onClose: (notification: NotificationEntity) => void
}

export default function Notification({ notification, animationIn, animationOut, onClose }: Props) {
  const [visible, setVisible] = useState(true)
  const [hovered, setHovered] = useState(false)

  useEffect(() => {
    if (notification.duration && !hovered) {
      const timeout = setTimeout(() => {
        setVisible(false)

        setTimeout(() => {
          onClose(notification)
        }, 1000)
      }, notification.duration + 1000)

      return () => clearTimeout(timeout)
    }
  }, [notification, hovered, onClose])

  const handleClose = useCallback(() => {
    setVisible(false)

    setTimeout(() => {
      onClose(notification)
    }, 1000)
  }, [notification, onClose])

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={cx('mt-5', 'rounded-md', {
        [animationIn]: visible,
        [animationOut]: !visible,
        'bg-green-200': notification.type === 'success',
        'bg-yellow-200': notification.type === 'warning',
        'bg-red-200': notification.type === 'error',
      })}
    >
      <div
        className={cx('p-2', 'rounded-t-md', 'flex', 'flex-row', 'justify-between', 'items-center', {
          'bg-green-600 text-green-100': notification.type === 'success',
          'bg-yellow-600 text-yellow-100': notification.type === 'warning',
          'bg-red-600 text-red-100': notification.type === 'error',
        })}
      >
        <span>{notification.title}</span>
        <button className="focus:outline-none" onClick={handleClose}>
          <FiX size={18} />
        </button>
      </div>
      <div
        className={cx('p-2', {
          'text-green-900': notification.type === 'success',
          'text-yellow-900': notification.type === 'warning',
          'text-red-900': notification.type === 'error',
        })}
      >
        {notification.content}
      </div>
    </div>
  )
}
