import { pathOr } from 'ramda'
import { AnyAction } from 'redux'
import { OrganizationalUnit } from 'entities'
import update from 'utility/update'

export interface State {
  loading: boolean
  entities: Record<number, OrganizationalUnit>
  result: number[]
}

const initialState: State = {
  loading: false,
  entities: {},
  result: [],
}

export default function organizationalUnitsReducer(
  state: State = initialState,
  { payload, type }: AnyAction
): State {
  switch (type) {
    case 'data/FETCH_ORGANIZATIONAL_UNITS_REQUEST': {
      return update(state, {
        loading: {
          $set: true,
        },
      })
    }
    case 'data/FETCH_ORGANIZATIONAL_UNITS_SUCCESS': {
      return update(state, {
        entities: {
          $set: pathOr({}, ['entities', 'organizationalUnits'], payload),
        },
        result: {
          $set: pathOr([], ['result'], payload),
        },
        loading: {
          $set: false,
        },
      })
    }
    case 'data/FETCH_ORGANIZATIONAL_UNITS_FAILURE': {
      return update(state, {
        loading: {
          $set: false,
        },
      })
    }
    case 'data/RESET': {
      return initialState
    }
  }

  return state
}
