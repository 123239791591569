import { SagaIterator } from 'redux-saga'
import { spawn } from 'redux-saga/effects'

import attachmentsSaga from './sagas/attachmentsSaga'
import organizationalUnitsSaga from './sagas/organizationalUnitsSaga'
import ticketsSaga from './sagas/ticketsSaga'

export default function* apiSaga(): SagaIterator {
  yield spawn(attachmentsSaga)
  yield spawn(organizationalUnitsSaga)
  yield spawn(ticketsSaga)
}
