import React, { useCallback, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
//@ts-ignore
import { Document, Page, Outline } from 'react-pdf/dist/esm/entry.webpack'

interface Props {
  file: string
}

export default function Pdf({ file }: Props) {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages)
  }, [])

  const handleNext = useCallback(() => {
    setPageNumber(pageNumber + 1)
  }, [pageNumber])

  const handlePrev = useCallback(() => {
    setPageNumber(pageNumber - 1)
  }, [pageNumber])

  return (
    <div className="h-full flex flex-col justify-between items-center">
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
        <Outline />
      </Document>

      {numPages && (
        <div className="shadow-md absolute rounded-full bottom-0 mb-5 flex">
          <button
            disabled={pageNumber === 1}
            onClick={handlePrev}
            className="focus:outline-none bg-gray-100 rounded-l-full px-4"
          >
            <FiChevronLeft className={pageNumber === 1 ? 'text-gray-300' : 'text-gray-600'} />
          </button>
          <p className="text-gray-600 text-sm p-4 bg-white">
            Side {pageNumber} af {numPages}
          </p>
          <button
            disabled={pageNumber === numPages}
            onClick={handleNext}
            className="focus:outline-none bg-gray-100 rounded-r-full px-4"
          >
            <FiChevronRight className={pageNumber === numPages ? 'text-gray-300' : 'text-gray-600'} />
          </button>
        </div>
      )}
    </div>
  )
}
