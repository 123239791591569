import cx from 'classnames'
import { FiX } from 'react-icons/fi'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { map, pathOr, sum } from 'ramda'
import Pdf from 'components/Pdf'
import { Attachment, Document } from 'entities'
import { FcDocument } from 'react-icons/fc'
import size from 'utility/size'

export interface PreviewComponent {
  open: (attachment: Attachment) => void
}

export default forwardRef<PreviewComponent>((_, forwardedRef) => {
  const [attachment, setAttachment] = useState<Attachment | undefined>(undefined)
  const [currentDocument, setCurrentDocument] = useState<Document | undefined>(undefined)

  useImperativeHandle(forwardedRef, () => ({
    open(attachment: Attachment) {
      setAttachment(attachment)
    },
  }))

  useEffect(() => {
    if (attachment?.type === 'package') {
      setCurrentDocument(attachment.documents[0])
    } else if (attachment?.type === 'document') {
      setCurrentDocument(attachment)
    }
  }, [attachment])

  const handleClose = useCallback(() => {
    setAttachment(undefined)
  }, [])

  if (!attachment) {
    return null
  }

  return (
    <div className="absolute inset-0 bg-white flex justify-center z-50">
      <div className="absolute top-0 right-0 p-4 text-gray-600 z-40">
        <button onClick={handleClose} className="focus:outline-none hover:text-gray-900">
          <FiX size={32} />
        </button>
      </div>
      {attachment.type === 'package' && (
        <div className="flex flex-col justify-between w-1/5 absolute inset-y-0 left-0 bg-gray-100">
          <div className="flex flex-col overflow-x-auto">
            <h3 className="text-lg mb-3 p-5 font-bold">{attachment.title}</h3>
            {attachment.documents.map((document) => (
              <div
                key={document.id}
                className={cx(
                  'flex',
                  'text-gray-700',
                  'focus:outline-none',
                  'hover:text-gray-900',
                  'justify-between',
                  'w-full',
                  'p-3',
                  {
                    'bg-blue-100': currentDocument === document,
                  }
                )}
              >
                <button
                  onClick={() => setCurrentDocument(document)}
                  className="flex flex-row w-1/2 focus:outline-none"
                >
                  <div>
                    <FcDocument size={48} className="mr-2" />
                  </div>
                  <div className="flex flex-col items-start w-full">
                    <span className="truncate text-left w-full" title={document.title}>
                      {document.title}
                    </span>
                    <span className="truncate text-left w-full" title={document.organizationalUnit?.name}>
                      {document.organizationalUnit?.name}
                    </span>
                  </div>
                </button>
              </div>
            ))}
          </div>
          <div className="bg-gray-200 p-5 flex flex-col">
            {attachment.documents.length} bilag,{' '}
            {size(sum(map(pathOr(0, ['file', 'size']), attachment.documents)))}
          </div>
        </div>
      )}
      <div>{currentDocument && <Pdf file={currentDocument.file.url} />}</div>
    </div>
  )
})
