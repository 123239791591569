import { identity, pathOr } from 'ramda'
import { denormalize } from 'normalizr'
import { createSelector, createStructuredSelector } from 'reselect'
import organizationalUnitSchema from 'schemas/organizationalUnitSchema'
import { OrganizationalUnit } from 'entities'
import hierarchize from 'utility/hierarchize'

interface Selection {
  isLoading: boolean
  organizationalUnits: OrganizationalUnit[]
}

const selectIsLoading = pathOr(false, ['data', 'organizationalUnits', 'loading'])
const selectOrganizationalUnits = pathOr({}, ['data', 'organizationalUnits', 'entities'])
const selectOrganizationalUnitIds = pathOr([], ['data', 'organizationalUnits', 'result'])

const organizationalUnitsSelector = (ids: number[], entities: Record<string, OrganizationalUnit>) => {
  const organizationalUnits = denormalize(ids, [organizationalUnitSchema], { organizationalUnits: entities })

  return hierarchize<OrganizationalUnit>(organizationalUnits)
}

export default createStructuredSelector<Record<string, unknown>, Selection>({
  isLoading: createSelector(selectIsLoading, identity),
  organizationalUnits: createSelector(
    selectOrganizationalUnitIds,
    selectOrganizationalUnits,
    organizationalUnitsSelector
  ),
})
