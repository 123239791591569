import { normalize } from 'normalizr'
import { SagaIterator } from 'redux-saga'
import { put, takeLatest } from 'redux-saga/effects'
import documentsSchema from 'schemas/documentsSchema'
import packageSchema from 'schemas/packagesSchema'
import api from 'api'
import createAction from 'utility/createAction'
import { Attachment } from 'entities'

function* fetchRequest() {
  try {
    const response = (yield api.get('/attachments')) as Attachment[]

    const attachments = normalize(response, {
      documents: [documentsSchema],
      packages: [packageSchema],
    })

    yield put(createAction('data/FETCH_ATTACHMENTS_SUCCESS', attachments))
  } catch (error) {
    yield put(createAction('data/FETCH_ATTACHMENTS_FAILURE', { error }))
  }
}

export default function* attachmentsSaga(): SagaIterator {
  yield takeLatest('data/FETCH_ATTACHMENTS_REQUEST', fetchRequest)
}
