import { Attachment } from 'entities'
import { pathOr, prop } from 'ramda'
import { AnyAction } from 'redux'
import update from 'utility/update'

export interface State {
  filters: {
    organizationalUnit?: number
    title: string
    healthConditionCategory?: number
    combineWithLetter?: boolean
    documentCategory?: number
    organizationalUnitFilter?: number
    hideSublevels: boolean
  }
  sorting: {
    key: keyof Attachment
    direction: 'asc' | 'desc'
  }
}

const initialState: State = {
  filters: {
    organizationalUnit: undefined,
    healthConditionCategory: undefined,
    title: '',
    combineWithLetter: undefined,
    documentCategory: undefined,
    organizationalUnitFilter: undefined,
    hideSublevels: false,
  },
  sorting: {
    key: 'title',
    direction: 'asc',
  },
}

export function attachmentsReducer(state: State = initialState, { payload, type }: AnyAction): State {
  switch (type) {
    case 'attachments/filters/SET': {
      return update(state, {
        filters: {
          [prop('key', payload)]: {
            $set: pathOr(undefined, ['value'], payload),
          },
        },
      })
    }
    case 'attachments/sorting/SET': {
      return update(state, {
        sorting: {
          key: {
            $set: prop('key', payload),
          },
          direction: {
            $set: prop('direction', payload),
          },
        },
      })
    }
  }

  return state
}

export default attachmentsReducer
