import { filter, identity, length, map, pathOr, propEq, propOr, sum } from 'ramda'
import { denormalize } from 'normalizr'
import { createSelector, createStructuredSelector } from 'reselect'
import { Document, OrganizationalUnit, Ticket } from 'entities'
import ticketsSchema from 'schemas/ticketsSchema'

interface Selection {
  isLoading: boolean
  ticket: Ticket
  ticketItems: number
  ticketSize: number
  valid: {
    size: boolean
    documents: boolean
    combineWithLetter: boolean
  }
  isValid: boolean
}

const selectIsLoading = pathOr(false, ['data', 'tickets', 'loading'])
const selectId = pathOr(undefined, ['data', 'tickets', 'result'])

interface TicketsSelector {
  organizationalUnits: Record<number, OrganizationalUnit>
  tickets: Record<number, Ticket>
}

const entitiesSelector = createStructuredSelector({
  organizationalUnits: pathOr({}, ['data', 'organizationalUnits', 'entities']),
  documents: pathOr({}, ['data', 'attachments', 'entities', 'documents']),
  tickets: pathOr({}, ['data', 'tickets', 'entities']),
})

const ticketSelector = (entities: TicketsSelector, id?: number) => {
  return denormalize(id, ticketsSchema, entities)
}

const ticketItemsSelector = (ticket: Ticket) => {
  return length(pathOr<Document[]>([], ['documents'], ticket))
}

const ticketSizeSelector = (ticket: Ticket) => {
  return sum(map(pathOr(0, ['file', 'size']), ticket.documents))
}

const validSelector = (ticket: Ticket) => {
  if (!ticket.documents) {
    return {
      size: true,
      documents: true,
      combineWithLetter: true,
    }
  }

  return {
    size: ticketSizeSelector(ticket) <= 7340032,
    documents: length(propOr([], 'documents', ticket)) <= 10,
    combineWithLetter:
      length(filter(propEq('combineWithLetter', true), propOr([], 'documents', ticket))) <= 1,
  }
}

const isValidSelector = (valid: Selection['valid']) => {
  return valid.combineWithLetter === true
}

export const selectedTicket = createSelector(entitiesSelector, selectId, ticketSelector)
const selectedValid = createSelector(selectedTicket, validSelector)

export default createStructuredSelector<Record<string, unknown>, Selection>({
  isLoading: createSelector(selectIsLoading, identity),
  ticket: selectedTicket,
  ticketSize: createSelector(selectedTicket, ticketSizeSelector),
  ticketItems: createSelector(selectedTicket, ticketItemsSelector),
  valid: selectedValid,
  isValid: createSelector(selectedValid, isValidSelector),
})
