import cx from 'classnames'
import React, { useRef } from 'react'
import { FcDocument } from 'react-icons/fc'
import { FiTrash } from 'react-icons/fi'
import { Document } from 'entities'
import size from 'utility/size'
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd'

interface Props {
  document: Document
  valid: any
  index: number
  id: number
  onPreview: (document: Document) => void
  onRemove: (document: Document) => void
  onMove: (dragIndex: number, hoverIndex: number) => void
}

interface DragItem {
  index: number
  id: string
  type: string
}

export const TicketContentItem = ({ document, valid, onPreview, onRemove, onMove, id, index }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const [, drop] = useDrop({
    accept: 'document',
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      onMove(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'document',
    item: { id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <div
      ref={ref}
      key={document.id}
      style={{ opacity }}
      className={cx(
        'flex',
        'text-gray-700',
        'focus:outline-none',
        'hover:text-gray-900',
        'justify-between',
        'w-full',
        'px-4',
        'py-3',
        {
          'bg-red-200': document.combineWithLetter && !valid.combineWithLetter,
        }
      )}
    >
      <button onClick={() => onPreview(document)} className="flex flex-row w-3/5 focus:outline-none">
        <div>
          <FcDocument size={48} className="mr-2" />
        </div>
        <div className="flex flex-col items-start w-full">
          <span className="truncate text-left w-full" title={document.title}>
            {document.title}
          </span>
          <span className="truncate text-left w-full" title={document.organizationalUnit?.name}>
            {document.organizationalUnit?.name}
          </span>
        </div>
      </button>
      <div className="flex h-full flex-col items-end justify-between">
        <div className="whitespace-no-wrap text-sm">{size(document.file.size)}</div>
        <div>
          <button
            className="hover:text-red-500 focus:outline-none p-2 -mr-2"
            onClick={() => onRemove(document)}
          >
            <FiTrash size={12} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default TicketContentItem
