import React, { useCallback } from 'react'
import Notification from 'components/Notification'
import { useDispatch, useSelector } from 'react-redux'
import notificationsSelector from 'store/selectors/notificationsSelector'
import createAction from 'utility/createAction'

export default function Notifications() {
  const dispatch = useDispatch()
  const { leftNotifications, rightNotifications } = useSelector(notificationsSelector)

  const handleClose = useCallback(
    (notification) => {
      dispatch(createAction('notifications/CLOSE', notification))
    },
    [dispatch]
  )

  return (
    <React.Fragment>
      <div className="absolute bottom-0 left-0 mb-4 ml-4 w-88 rounded-md shadow-sm z-50">
        {leftNotifications.map((notification) => (
          <Notification
            animationIn="animate-fade-in-left"
            animationOut="animate-fade-out-left"
            key={notification.id}
            notification={notification}
            onClose={handleClose}
          />
        ))}
      </div>
      <div className="absolute bottom-0 right-0 mb-4 mr-4 w-88 rounded-md shadow-sm z-50">
        {rightNotifications.map((notification) => (
          <Notification
            animationIn="animate-fade-in-right"
            animationOut="animate-fade-out-right"
            key={notification.id}
            notification={notification}
            onClose={handleClose}
          />
        ))}
      </div>
    </React.Fragment>
  )
}
