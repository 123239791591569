import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FcHighPriority } from 'react-icons/fc'
import appSelector from 'store/selectors/appSelector'
import AttachmentTable from 'components/AttachmentTable'
import TicketSidebar, { TicketSidebarComponent } from 'components/TicketSidebar'
import Preview, { PreviewComponent } from 'components/Preview'
import OrganizationalUnitsSidebar, {
  OrganizationalUnitsSidebarComponent,
} from 'components/OrganizationalUnitsSidebar'
import Notifications from 'components/Notifications'
import TicketBasket from 'components/TicketBasket'
import Filters from 'components/Filters'
import createAction from 'utility/createAction'
import filtersSelector from 'store/selectors/filtersSelector'
import { ReactComponent as Loading } from 'assets/svg/loading.svg'
import logo from 'assets/images/logo.png'

function App() {
  const dispatch = useDispatch()
  const organizationalUnitsSidebar = useRef<OrganizationalUnitsSidebarComponent>(null)
  const ticketSidebar = useRef<TicketSidebarComponent>(null)
  const preview = useRef<PreviewComponent>(null)
  const { organizationalUnitName } = useSelector(filtersSelector)

  const { isLoading, error } = useSelector(appSelector)

  useEffect(() => {
    dispatch(createAction('app/INITIALIZE'))
  }, [dispatch])

  const toggleOrganizationalUnitsSidebar = useCallback(() => {
    organizationalUnitsSidebar.current!.toggle()
  }, [organizationalUnitsSidebar])

  const toggleTicketSidebar = useCallback(() => {
    ticketSidebar.current!.toggle()
  }, [ticketSidebar])

  if (isLoading) {
    return (
      <div className="bg-white absolute h-screen inset-0 flex items-center justify-center">
        <Loading className="text-blue-500" width={84} height={84} />
      </div>
    )
  }

  if (error) {
    return (
      <div className="bg-white absolute h-screen inset-0 flex flex-col items-center justify-center">
        <FcHighPriority size={84} />
        <p className="py-4">{error}</p>
      </div>
    )
  }

  return (
    <div className="h-screen flex flex-col justify-start font-sans">
      <header className="bg-gray-500 py-3">
        <div className="flex justify-between items-center px-4 pb-4">
          <div className="flex flex-row items-center">
            <img src={logo} alt="Region Hovedstaden - DiPo" style={{ height: '63px' }} />

            <button
              onClick={toggleOrganizationalUnitsSidebar}
              className="bg-white rounded-md text-blue-900 py-2 px-3 focus:outline-none flex items-center ml-5 hover:shadow-outline-blue-900"
            >
              {organizationalUnitName}
            </button>
          </div>

          <TicketBasket
            className="bg-white rounded-md  text-blue-900 py-2 px-3 focus:outline-none"
            onClick={toggleTicketSidebar}
          />
        </div>

        <div className="px-4">
          <Filters />
        </div>
      </header>

      <main className="flex-1 overflow-y-auto">
        <AttachmentTable previewRef={preview} />
      </main>

      <Preview ref={preview} />
      <OrganizationalUnitsSidebar ref={organizationalUnitsSidebar} />
      <TicketSidebar ref={ticketSidebar} previewRef={preview} />
      <Notifications />
    </div>
  )
}

export default App
