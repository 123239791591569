import { always, cond, equals } from 'ramda'
import cx from 'classnames'
import React, { useCallback } from 'react'
import { FiX } from 'react-icons/fi'

type Props = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  title: string
  value: boolean | undefined
  filterKey: string
  onChange: (filterKey: string, value: boolean | undefined) => void
  onClear: (filterKey: string) => void
}

export default function BooleanFilter({ title, filterKey, value, onChange, onClear, ...otherProps }: Props) {
  const handleChange = useCallback(
    (value: string) => {
      const parsedValue = cond<string, boolean | undefined>([
        [equals('true'), always(true)],
        [equals('false'), always(false)],
        [equals('undefined'), always(undefined)],
      ])(value)

      onChange(filterKey, parsedValue)
    },
    [filterKey, onChange]
  )

  const handleClear = useCallback(() => {
    onClear(filterKey)
  }, [filterKey, onClear])

  return (
    <div {...otherProps}>
      <p className="text-blue-900 text-xs">{title}</p>

      <div className="w-full flex flex-row items-stretch justify-between bg-white rounded-md focus-within:shadow-outline-blue-900">
        <select
          className="pl-2 h-10 rounded-l-md text-sm focus:outline-none w-full"
          onChange={(e) => handleChange(e.target.value)}
          value={String(value)}
        >
          <option value="undefined">Alle</option>
          <option value="true">Ja</option>
          <option value="false">Nej</option>
        </select>
        <button
          className={cx(
            'px-1',
            'h-10',
            'rounded-r-md',
            'flex',
            'items-center',
            'justify-center',
            'focus:outline-none',
            {
              'text-red-500 cursor-pointer': value !== undefined,
              'text-gray-500 cursor-default': value === undefined,
            }
          )}
          onClick={handleClear}
        >
          <FiX size={18} />
        </button>
      </div>
    </div>
  )
}
