import { Notification } from 'entities'
import { AnyAction } from 'redux'
import update from 'utility/update'

interface NotificationAction extends AnyAction {
  payload: Notification
}

type State = Notification[]

const initialState: State = []

export default function notificationsReducer(
  state: State = initialState,
  { payload, type }: NotificationAction
): State {
  switch (type) {
    case 'notifications/ADD': {
      return update(state, {
        $push: [
          {
            ...payload,
            id: Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10),
          },
        ],
      })
    }
    case 'notifications/CLOSE': {
      return update(state, {
        $without: [payload],
      })
    }
  }

  return state
}
