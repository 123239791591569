import { OrganizationalUnit } from 'entities'
import { filter, identity, isEmpty, isNil, length, pathOr } from 'ramda'
import { createSelector, createStructuredSelector } from 'reselect'

interface Option {
  id: number
  name: string
}
interface Selection {
  organizationalUnit?: number
  organizationalUnitName?: string
  sharedOrganizationalUnit?: number
  title: string
  type?: string
  healthConditionCategory?: Option
  documentCategory?: Option
  organizationalUnitFilter?: Option
  combineWithLetter?: boolean
  hideSublevels: boolean
}

const selectOrganizationalUnits = pathOr({}, ['data', 'organizationalUnits', 'entities'])

const selectOrganizationalUnitFilter = pathOr(undefined, ['attachments', 'filters', 'organizationalUnit'])
const selectTitleFilter = pathOr('', ['attachments', 'filters', 'title'])
const selectTypeFilter = pathOr(undefined, ['attachments', 'filters', 'type'])
const selectHideSublevelsFilter = pathOr(false, ['attachments', 'filters', 'hideSublevels'])
const selectDocumentCategoryFilter = pathOr(undefined, ['attachments', 'filters', 'documentCategory'])
const selectOrganizationalUnitFilterFilter = pathOr(undefined, [
  'attachments',
  'filters',
  'organizationalUnitFilter',
])
const selectCombineWithLetterFilter = pathOr(undefined, ['attachments', 'filters', 'combineWithLetter'])

const selectHealthConditionCategoryFilter = pathOr(undefined, [
  'attachments',
  'filters',
  'healthConditionCategory',
])

const organizationalUnitNameSelector = (
  organizationalUnits: Record<number, OrganizationalUnit>,
  organizationalUnitId?: number
) => {
  return organizationalUnitId ? organizationalUnits[organizationalUnitId].name : ''
}

const selectedOrganizationalUnitId = createSelector(selectOrganizationalUnitFilter, identity)
const selectedTitleFilter = createSelector(selectTitleFilter, identity)
const selectedTypeFilter = createSelector(selectTypeFilter, identity)
const selectedHideSublevelsFilter = createSelector(selectHideSublevelsFilter, identity)

export const selectedDocumentCategoryFilter = createSelector(selectDocumentCategoryFilter, identity)
export const selectedOrganizationalUnitFilterFilter = createSelector(
  selectOrganizationalUnitFilterFilter,
  identity
)
export const selectedHealthConditionCategoryFilter = createSelector(
  selectHealthConditionCategoryFilter,
  identity
)
const selectedCombineWithLetterFilter = createSelector(selectCombineWithLetterFilter, identity)

export default createStructuredSelector<Record<string, unknown>, Selection>({
  organizationalUnit: selectedOrganizationalUnitId,
  organizationalUnitName: createSelector(
    selectOrganizationalUnits,
    selectedOrganizationalUnitId,
    organizationalUnitNameSelector
  ),
  title: selectedTitleFilter,
  type: selectedTypeFilter,
  documentCategory: selectedDocumentCategoryFilter,
  organizationalUnitFilter: selectedOrganizationalUnitFilterFilter,
  healthConditionCategory: selectedHealthConditionCategoryFilter,
  combineWithLetter: selectedCombineWithLetterFilter,
  hideSublevels: selectedHideSublevelsFilter,
})
