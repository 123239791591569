import { SagaIterator } from 'redux-saga'
import { spawn } from 'redux-saga/effects'

import dataRootSaga from './data/dataRootSaga'
import appSaga from './sagas/appSaga'
import ticketSaga from './sagas/ticketSaga'

export default function* rootSaga(): SagaIterator {
  yield spawn(dataRootSaga)

  yield spawn(appSaga)
  yield spawn(ticketSaga)
}
