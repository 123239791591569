import { schema } from 'normalizr'
import { assoc } from 'ramda'
import organizationalUnitSchema from './organizationalUnitSchema'

const idAttribute = 'id'
const relations = {
  organizationalUnit: organizationalUnitSchema,
}

export default new schema.Entity('documents', relations, {
  idAttribute,
  processStrategy: (entity) => assoc('type', 'document', entity),
})
