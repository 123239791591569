import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import filterOptionsSelector from 'store/selectors/filterOptionsSelector'
import createAction from 'utility/createAction'
import SelectFilter from 'components/Filters/SelectFilter'
import BooleanFilter from 'components/Filters/BooleanFilter'
import TextFilter from 'components/Filters/TextFilter'
import TypeFilter from 'components/Filters/TypeFilter'
import CheckboxFilter from 'components/Filters/CheckboxFilter'
import filtersSelector from 'store/selectors/filtersSelector'

const Filters = () => {
  const dispatch = useDispatch()
  const {
    type,
    title,
    hideSublevels,
    healthConditionCategory,
    combineWithLetter,
    documentCategory,
    organizationalUnitFilter,
  } = useSelector(filtersSelector)
  const {
    healthConditionCategoryOptions,
    documentCategoryOptions,
    organizationalUnitFilterOptions,
  } = useSelector(filterOptionsSelector)

  const handleChange = useCallback(
    (key, value) => {
      dispatch(createAction('attachments/filters/SET', { key, value }))
    },
    [dispatch]
  )

  const handleClear = useCallback(
    (key) => {
      dispatch(createAction('attachments/filters/SET', { key, value: undefined }))
    },
    [dispatch]
  )

  return (
    <>
      <div className="flex flex-row">
        <CheckboxFilter
          filterKey="hideSublevels"
          title="Medtag ikke elementer i undermapper"
          onChange={handleChange}
          onClear={handleClear}
          value={hideSublevels}
          className="flex flex-row items-center space-x-2"
        />
      </div>
      <div className="flex flex-row items-end">
        <TypeFilter
          filterKey="type"
          title="Bilag/Pakker"
          onChange={handleChange}
          onClear={handleClear}
          value={type}
          className="w-1/12"
        />
        <TextFilter
          filterKey="title"
          title="Søg på titel"
          onChange={handleChange}
          onClear={handleClear}
          className="ml-2 w-3/12"
          value={title}
        />
        <SelectFilter
          filterKey="organizationalUnitFilter"
          title="Vælg afsnit"
          onChange={handleChange}
          onClear={handleClear}
          value={organizationalUnitFilter}
          options={organizationalUnitFilterOptions}
          className="ml-2 w-3/12"
        />
        <SelectFilter
          filterKey="documentCategory"
          title="Vælg dokumentkategori"
          onChange={handleChange}
          onClear={handleClear}
          value={documentCategory}
          options={documentCategoryOptions}
          className="ml-2 w-2/12"
        />
        <SelectFilter
          filterKey="healthConditionCategory"
          title="Vælg sygdomskategori"
          onChange={handleChange}
          onClear={handleClear}
          value={healthConditionCategory}
          options={healthConditionCategoryOptions}
          className="ml-2 w-2/12"
        />
        <BooleanFilter
          filterKey="combineWithLetter"
          title="Se kun forberedelsesbilag"
          onChange={handleChange}
          onClear={handleClear}
          value={combineWithLetter}
          className="ml-2 w-1/12"
        />
      </div>
    </>
  )
}

export default Filters
