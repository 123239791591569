import React from 'react'
import TicketContentItem from 'components/TicketContentItem'
import { Document } from 'entities'

interface Props {
  documents: Document[]
  onPreview: (document: Document) => void
  onRemove: (document: Document) => void
  onMove: (dragIndex: number, hoverIndex: number) => void
  valid: any
}

export default function TicketContent({ documents, valid, onMove, onPreview, onRemove }: Props) {
  return (
    <React.Fragment>
      {documents.map((document, index) => (
        <TicketContentItem
          key={document.id}
          index={index}
          id={document.id}
          valid={valid}
          document={document}
          onPreview={onPreview}
          onRemove={onRemove}
          onMove={onMove}
        />
      ))}
    </React.Fragment>
  )
}
