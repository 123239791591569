import createSagaMiddleware from 'redux-saga'
import { applyMiddleware, createStore, Dispatch, AnyAction } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { setApiCredentials } from 'api/helpers'
import { ENVIRONMENT } from 'environment'

import rootReducer from './rootReducer'
import rootSaga from './rootSaga'

// Credentials middleware
const setCredentials = () => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
  if (action.type === 'app/SET_CREDENTIALS') {
    setApiCredentials(action.payload)
  }

  return next(action)
}

const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware, setCredentials]

const composed =
  ENVIRONMENT === 'local'
    ? composeWithDevTools(applyMiddleware(...middlewares))
    : applyMiddleware(...middlewares)

const store = createStore(rootReducer, composed)

sagaMiddleware.run(rootSaga)

export default store
