import cx from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { FiX } from 'react-icons/fi'

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  filterKey: string
  title: string
  value: string
  onChange: (filterKey: string, value: string) => void
  onClear: (filterKey: string) => void
}

export default function TextFilter({ filterKey, title, value, onChange, onClear, ...divProps }: Props) {
  const [internalValue, setInternalValue] = useState(value)

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(filterKey, internalValue)
    }, 500)
    return () => clearTimeout(timeout)
  }, [filterKey, internalValue, onChange])

  const handleClear = useCallback(() => {
    onClear(filterKey)
    setInternalValue('')
  }, [filterKey, onClear])

  return (
    <div {...divProps}>
      <p className="text-blue-900 text-xs">{title}</p>

      <div className="w-full flex flex-row items-stretch justify-between bg-white rounded-md focus-within:shadow-outline-blue-900">
        <input
          placeholder="Skriv her…"
          className="px-2 text-sm h-10 rounded-l-md focus:outline-none w-full"
          onChange={(event) => setInternalValue(event.target.value)}
          value={internalValue}
        />
        <button
          className={cx(
            'px-1',
            'h-10',
            'rounded-r-md',
            'flex',
            'items-center',
            'justify-center',
            'focus:outline-none',
            {
              'text-red-500 cursor-pointer': value !== '',
              'text-gray-500 cursor-default': value === '',
            }
          )}
          onClick={handleClear}
        >
          <FiX size={18} />
        </button>
      </div>
    </div>
  )
}
