import { Notification } from 'entities'
import { filter, pathOr, propEq } from 'ramda'
import { createSelector, createStructuredSelector } from 'reselect'

interface Selection {
  leftNotifications: Notification[]
  rightNotifications: Notification[]
}

const selectNotifications = pathOr([], ['notifications'])

const leftNotificationsSelector = (notifications: Notification[]) => {
  return filter(propEq('position', 'left'), notifications)
}

const rightNotificationsSelector = (notifications: Notification[]) => {
  return filter(propEq('position', 'right'), notifications)
}

export default createStructuredSelector<Record<string, unknown>, Selection>({
  leftNotifications: createSelector(selectNotifications, leftNotificationsSelector),
  rightNotifications: createSelector(selectNotifications, rightNotificationsSelector),
})
