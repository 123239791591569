import cx from 'classnames'
import { isNil } from 'ramda'
import { FcDocument, FcPackage } from 'react-icons/fc'
import { FiCheck, FiSearch, FiTrash } from 'react-icons/fi'
import React, { useCallback, useEffect, useState } from 'react'
import { DenormalizedAttachment } from 'entities'
import { ReactComponent as Loading } from 'assets/svg/loading.svg'
import { formatDate } from 'utility/formatDate'

interface Props {
  attachment: DenormalizedAttachment
  index: number
  onAdd: (attachment: DenormalizedAttachment) => void
  onPreview: (attachment: DenormalizedAttachment) => void
  onRemove: (attachment: DenormalizedAttachment) => void
}

export const AttachmentTableRow = ({ attachment, index, onAdd, onPreview, onRemove }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [action, setAction] = useState(attachment.isAdded ? 'remove' : 'add')

  const handlePreview = useCallback(
    (e) => {
      e.stopPropagation()
      onPreview(attachment)
    },
    [attachment, onPreview]
  )

  const handleAdd = useCallback(
    (e) => {
      e.stopPropagation()

      if (isLoading || attachment.isAdded) {
        return
      }

      onAdd(attachment)
      setIsLoading(true)
    },
    [attachment, onAdd, isLoading]
  )

  const handleRemove = useCallback(
    (e) => {
      e.stopPropagation()

      if (isLoading || !attachment.isAdded) {
        return
      }

      onRemove(attachment)
      setIsLoading(true)
    },
    [attachment, onRemove, isLoading]
  )

  useEffect(() => {
    setIsLoading(false)

    if (attachment.isAdded) {
      setTimeout(() => {
        setAction('remove')
      }, 700)
    }

    if (!attachment.isAdded) {
      setAction('add')
    }
  }, [attachment])

  const unitCode = attachment?.organizationalUnit?.unitCode

  return (
    <tr
      key={attachment.id}
      className={cx('h-20', {
        'bg-gray-100': index % 2 === 0,
        'bg-white': index % 2 !== 0,
      })}
    >
      <td className="border-t border-b px-4 py-3 w-5/12">
        <div className="flex items-center w-full">
          {attachment.type === 'document' ? (
            <div>
              <FcDocument size={32} className="mr-2" />
            </div>
          ) : (
            <div className="relative">
              <FcPackage size={32} className="mr-2" />
              <span className="absolute bottom-0 right-0 mr-1 shadow-sm rounded-full bg-white text-xs w-4 h-4 flex justify-center items-center text-gray-500">
                {attachment.documents.length}
              </span>
            </div>
          )}
          <div className="relative w-11/12">
            <p className="truncate" title={attachment.title}>
              {attachment.title}
            </p>
            <div className="absolute flex flex-row -mx-2">
              {attachment.type === 'document' && !isNil(attachment.clinicalTerm) && (
                <span className="rounded-full mx-2 px-1 text-xs bg-yellow-200 whitespace-no-wrap w-auto">
                  {attachment.clinicalTerm}
                </span>
              )}
              {attachment.type === 'document' && !isNil(attachment.documentCategory) && (
                <span className="rounded-full mx-2 px-1 text-xs bg-blue-200 whitespace-no-wrap w-auto">
                  {attachment.documentCategory.name}
                </span>
              )}
              {attachment.type === 'document' &&
                attachment.healthConditions.map((category) => (
                  <span
                    key={category.oid}
                    className="rounded-full mx-2 px-1 text-xs bg-green-200 whitespace-no-wrap w-auto"
                  >
                    {category.name}
                  </span>
                ))}
              {attachment.type === 'document' && attachment.combineWithLetter && (
                <span className="rounded-full mx-2 px-1 text-xs bg-red-200 whitespace-no-wrap w-auto">
                  Forberedelsesbilag
                </span>
              )}
            </div>
          </div>
        </div>
      </td>
      <td className="border-t border-b px-3 py-3 text-right text-xs">
        <div className="flex flex-row items-center justify-end">
          {action === 'add' && (
            <button
              onClick={handleAdd}
              disabled={attachment.isAdded || isLoading ? true : false}
              className="relative rounded-full w-12 h-8 flex items-center justify-center bg-blue-500 text-blue-100 hover:bg-blue-900 whitespace-no-wrap mr-3 focus:outline-none"
            >
              {attachment.isAdded ? <FiCheck /> : 'Tilføj'}

              {isLoading && (
                <div className="absolute rounded-full inset-0 flex justify-center items-center text-white bg-blue-500">
                  <Loading />
                </div>
              )}
            </button>
          )}
          {action === 'remove' && (
            <button
              onClick={handleRemove}
              disabled={isLoading ? true : false}
              className="relative rounded-full w-12 h-8 flex items-center justify-center bg-red-500 text-blue-100 hover:bg-red-900 whitespace-no-wrap mr-3 focus:outline-none"
            >
              <FiTrash />

              {isLoading && (
                <div className="absolute rounded-full inset-0 flex justify-center items-center text-white bg-red-500">
                  <Loading />
                </div>
              )}
            </button>
          )}
          <button onClick={handlePreview} className="text-gray-700 focus:outline-none hover:text-blue-500">
            <FiSearch size={28} />
          </button>
        </div>
      </td>
      <td className="border-t border-b px-3 py-3 w-2/12 text-sm">
        {attachment?.organizationalUnit?.name} {unitCode && `(${unitCode})`}
      </td>
      <td className="border-t border-b px-3 py-3 w-1/12 text-sm">{formatDate(attachment.updatedAt)}</td>
    </tr>
  )
}

export default AttachmentTableRow
