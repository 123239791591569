import { combineReducers } from 'redux'

import attachments from './reducers/attachmentsReducer'
import organizationalUnits from './reducers/organizationalUnitsReducer'
import tickets from './reducers/ticketsReducer'

export default combineReducers({
  attachments,
  organizationalUnits,
  tickets,
})
