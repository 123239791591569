import axios from 'axios'
import { BACKEND_URL, BACKEND_TOKEN } from 'environment'

export default axios.create({
  baseURL: BACKEND_URL,
  responseType: 'json',
  maxRedirects: 0,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Basic ${BACKEND_TOKEN}`,
  },
})
