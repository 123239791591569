import { SagaIterator } from 'redux-saga'
import { put, race, select, take, takeLatest } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import createAction from 'utility/createAction'
import ticketSelector from 'store/selectors/ticketSelector'
import { Attachment, Document } from 'entities'

function* addAttachment({ payload }: AnyAction): SagaIterator {
  const { attachment }: { attachment: Attachment } = payload
  const { ticket } = yield select(ticketSelector)

  const title = attachment.type === 'document' ? 'Bilag' : 'Bilagspakke'
  const documents = attachment.type === 'document' ? [attachment] : attachment.documents

  yield put(createAction('data/ADD_DOCUMENTS_REQUEST', { ticket, documents }))

  const { failure } = yield race({
    success: take('data/ADD_DOCUMENTS_SUCCESS'),
    failure: take('data/ADD_DOCUMENTS_FAILURE'),
  })

  if (failure) {
    yield put(
      createAction('notifications/ADD', {
        type: 'error',
        title: `${title} ikke tilføjet`,
        content: `${attachment.title} kunne ikke fjernes fra billetkurven. Prøv igen senere.`,
        duration: 2000,
        position: 'right',
      })
    )
  }
}

function* removeAttachment({ payload }: AnyAction): SagaIterator {
  const { attachment }: { attachment: Attachment } = payload
  const { ticket } = yield select(ticketSelector)

  const documents = attachment.type === 'document' ? [attachment] : attachment.documents

  yield put(createAction('data/REMOVE_DOCUMENTS_REQUEST', { ticket, documents }))

  const { success } = yield race({
    success: take('data/REMOVE_DOCUMENTS_SUCCESS'),
    failure: take('data/REMOVE_DOCUMENTS_FAILURE'),
  })
}

function* removeDocument({ payload }: AnyAction): SagaIterator {
  const { document }: { document: Document } = payload
  const { ticket } = yield select(ticketSelector)

  yield put(createAction('data/REMOVE_DOCUMENTS_REQUEST', { ticket, documents: [document] }))

  const { success } = yield race({
    success: take('data/REMOVE_DOCUMENTS_SUCCESS'),
    failure: take('data/REMOVE_DOCUMENTS_FAILURE'),
  })

  yield put(
    createAction('notifications/ADD', {
      type: success ? 'warning' : 'error',
      title: success ? `Bilag blev fjernet.` : `Bilag ikke fjernet`,
      content: success
        ? `${document.title} blev fjernet.`
        : `${document.title} kunne ikke fjernes fra billetkurven. Prøv igen senere.`,
      duration: 2000,
      position: 'left',
    })
  )
}

function* setDocuments({ payload }: AnyAction): SagaIterator {
  const { documents } = payload
  const { ticket } = yield select(ticketSelector)

  yield put(createAction('data/REPLACE_DOCUMENTS_REQUEST', { ticket, documents }))

  const { success } = yield race({
    success: take('data/REPLACE_DOCUMENTS_SUCCESS'),
    failure: take('data/REPLACE_DOCUMENTS_FAILURE'),
  })

  yield put(
    createAction('notifications/ADD', {
      type: success ? 'success' : 'error',
      title: success ? 'Valgte bilag blev reddet!' : 'Valgte bilag kunne ikke gemmes!',
      content: success
        ? 'Valgte bilag blev reddet'
        : 'Der opstod en fejl under lagring af din valgte bilag. Prøv igen senere.',
      duration: 2000,
      position: 'left',
    })
  )
}

function* send(): SagaIterator {
  const { ticket, isValid } = yield select(ticketSelector)

  if (!isValid) {
    return
  }

  window.location.href = ticket.closeUrl
}

export default function* ticketSaga(): SagaIterator {
  yield takeLatest('ticket/ADD_ATTACHMENT', addAttachment)
  yield takeLatest('ticket/REMOVE_ATTACHMENT', removeAttachment)
  yield takeLatest('ticket/REMOVE_DOCUMENT', removeDocument)
  yield takeLatest('ticket/SET_DOCUMENTS', setDocuments)
  yield takeLatest('ticket/SEND', send)
}
