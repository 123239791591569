import { indexOf, length } from 'ramda'
import cx from 'classnames'
import React, { useCallback } from 'react'
import { FiX } from 'react-icons/fi'

interface Option {
  id: number
  name: string
}

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  filterKey: string
  title: string
  value?: Option
  onChange: (filterKey: string, value: Option) => void
  onClear: (filterKey: string) => void
  options: Option[]
}

export default function SelectFilter({
  filterKey,
  title,
  value,
  options,
  onChange,
  onClear,
  ...divProps
}: Props) {
  const handleSelect = useCallback(
    (value) => {
      onChange(filterKey, options[value])
    },
    [filterKey, onChange, options]
  )

  const handleClear = useCallback(() => {
    onClear(filterKey)
  }, [filterKey, onClear])

  return (
    <div {...divProps}>
      <p className="text-blue-900 text-xs">{title}</p>

      <div className="w-full flex flex-row items-stretch justify-between bg-white rounded-md focus-within:shadow-outline-blue-900">
        <select
          className="pl-3 h-10 rounded-l-md text-sm focus:outline-none w-full"
          onChange={(e) => handleSelect(e.target.value)}
          disabled={length(options) === 0}
          value={indexOf(value, options)}
        >
          <option>Alle</option>
          {options.map((option, index) => (
            <option key={option.id} value={index}>
              {option.name}
            </option>
          ))}
        </select>

        <button
          className={cx(
            'px-1',
            'h-10',
            'rounded-r-md',
            'flex',
            'items-center',
            'justify-center',
            'focus:outline-none',
            {
              'text-red-500 cursor-pointer': value !== undefined,
              'text-gray-500 cursor-default': value === undefined,
            }
          )}
          onClick={handleClear}
        >
          <FiX size={18} />
        </button>
      </div>
    </div>
  )
}
