import { SagaIterator } from 'redux-saga'
import { OrganizationalUnit } from 'entities'
import { put, call, take, takeLatest, race, all } from 'redux-saga/effects'
import { find, pathOr, pipe, propEq, propOr, values } from 'ramda'
import createAction from 'utility/createAction'

function* initApp(): SagaIterator {
  const urlParams = new URLSearchParams(window.location.search)
  const key = urlParams.get('key')
  const departmentSKS = urlParams.get('departmentSKS')
  const hospitalSKS = urlParams.get('hospitalSKS')
  const ticketId = urlParams.get('ticketId')
  const closeUrl = urlParams.get('closeUrl')

  if (!key || !departmentSKS) {
    const error = 'Forbidden'
    yield put(createAction('app/SET_ERROR', { error }))

    return yield put(createAction('app/INITIALIZED'))
  }

  yield put(createAction('app/SET_CREDENTIALS', { key, departmentSKS, hospitalSKS }))

  const { success, failure } = yield call(fetchData)

  if (failure) {
    const error = 'Whoops, something went wrong.'
    yield put(createAction('app/SET_ERROR', { error }, { failure }))

    return yield put(createAction('app/INITIALIZED'))
  }

  const organizationUnits = pipe(
    pathOr({}, ['organizationalUnits', 'payload', 'entities', 'organizationalUnits']),
    values
  )

  const organizationalUnitId =
    pipe(organizationUnits, find<OrganizationalUnit>(propEq('level', 3)), propOr(undefined, 'id'))(success) ||
    pipe(organizationUnits, find<OrganizationalUnit>(propEq('level', 1)), propOr(undefined, 'id'))(success)

  yield put(
    createAction('attachments/filters/SET', {
      key: 'organizationalUnit',
      value: organizationalUnitId,
    })
  )

  if (ticketId) {
    yield put(createAction('data/FETCH_TICKET_REQUEST', { id: ticketId }))
  } else {
    yield put(createAction('data/CREATE_TICKET_REQUEST', { closeUrl }))
  }

  const { ticketFailure } = yield race({
    ticketFailure: take(ticketId ? 'data/FETCH_TICKET_FAILURE' : 'data/CREATE_TICKET_FAILURE'),
    ticketSuccess: take(ticketId ? 'data/FETCH_TICKET_SUCCESS' : 'data/CREATE_TICKET_SUCCESS'),
  })

  if (ticketFailure) {
    const error = 'Whoops, something went wrong.'
    yield put(createAction('app/SET_ERROR', { error }, { failure: ticketFailure }))

    return yield put(createAction('app/INITIALIZED'))
  }

  yield put(createAction('app/INITIALIZED'))
}

function* fetchData(): SagaIterator {
  yield put(createAction('data/FETCH_ORGANIZATIONAL_UNITS_REQUEST'))
  yield put(createAction('data/FETCH_ATTACHMENTS_REQUEST'))

  return yield race({
    failure: take(['data/FETCH_ORGANIZATIONAL_UNITS_FAILURE', 'data/FETCH_ATTACHMENTS_FAILURE']),
    success: all({
      organizationalUnits: take('data/FETCH_ORGANIZATIONAL_UNITS_SUCCESS'),
      attachments: take('data/FETCH_ATTACHMENTS_SUCCESS'),
    }),
  })
}

export default function* appSaga(): SagaIterator {
  yield takeLatest('app/INITIALIZE', initApp)
}
