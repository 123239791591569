import client from './client'
import { settle } from './helpers'

const Api = {
  get: <T>(endpoint: string, query?: object): Promise<T> => {
    return settle<T>(
      client.get(endpoint, {
        params: query,
      })
    )
  },

  post: <T>(endpoint: string, data?: object): Promise<T> => {
    return settle<T>(client.post(endpoint, data))
  },

  put: <T>(endpoint: string, data?: object): Promise<T> => {
    return settle<T>(client.put(endpoint, data))
  },

  patch: <T>(endpoint: string, data?: object): Promise<T> => {
    return settle<T>(client.patch(endpoint, data))
  },

  delete: <T>(endpoint: string, data?: object): Promise<T> => {
    return settle<T>(client.delete(endpoint, data))
  },
}

export default Api
