import { schema } from 'normalizr'
import documentsSchema from './documentsSchema'

const idAttribute = 'id'
const relations = {
  documents: [documentsSchema],
}

export default new schema.Entity('tickets', relations, {
  idAttribute,
})
